<template>
  <div class="va-page-not-found justify--center pb-5" :style="pageNotFoundStyle">
    <div class="va-page-not-found__inner align--center">
      <slot name="image" />
      <div class="va-page-not-found__title text--center mb-4">{{$t('404.title')}}</div>
      <div class="va-page-not-found__text px-4 text--center">
        <span>
          {{$t('404.text')}}
        </span>
      </div>
      <slot />
      <router-link to="/panel/dashboard">
        <va-button v-if="!withoutButton">
          {{$t('404.back_button')}}
        </va-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { ColorThemeMixin } from '../../../services/vuestic-ui';

export default {
  name: 'va-page-not-found',
  mixins: [ColorThemeMixin],
  inject: ['contextConfig'],
  components: {
  },
  props: { withoutButton: Boolean },
  computed: {
    pageNotFoundStyle () {
      return {
        color: this.contextConfig.invertedColor ? this.$themes.dark : 'white',
        backgroundColor: '',
        backgroundImage: 'linear-gradient(84.61deg, #4C96C5 0%, #9A6AA2 50.52%, #D84885 100%)',
      };
    },
  },
};
</script>

<style lang="scss">
  .va-page-not-found {
    min-height: 100vh;
    display: flex;
    padding-top: 10%;
    position: relative;
    align-items: center;

    @include media-breakpoint-down(sm) {
      padding-top: 8%;
    }

    &__inner {
      display: flex;
      flex-direction: column;

      .va-icon-vuestic {
        width: 19rem;
        height: 2rem;
        margin-bottom: 13%;
      }
    }

    &-large-text__number {
      line-height: 1;
    }

    &__title {
      font-size: 3rem;
      line-height: 1.25em;
      font-weight: 500;

      @include media-breakpoint-down(xs) {
        font-size: 1.5rem;
      }
    }

    &__text {
      margin-bottom: 2.5rem;
    }

    &__wallpaper {
      position: absolute;
      bottom: 0;
      left: 1rem;
      width: 30%;
      height: 40%;

      @include media-breakpoint-down(xs) {
        display: none;
      }
    }
  }
</style>
